import { useState, useCallback, useMemo, useEffect } from 'react'

export const useBoolean = (initialValue?: boolean) => {
    const [isTrue, setIsTrue] = useState<boolean>(initialValue ?? false)

    useEffect(() => {
        setIsTrue(initialValue ?? false)
    }, [initialValue])

    const setTrue = useCallback(() => {
        setIsTrue(true)
    }, [])

    const setFalse = useCallback(() => {
        setIsTrue(false)
    }, [])

    const toggle = useCallback(() => {
        setIsTrue(prevIsTrue => !prevIsTrue)
    }, [])

    const set = useCallback(setIsTrue, [setIsTrue])

    return useMemo(() => {
        return {
            isTrue,
            isFalse: !isTrue,
            setTrue,
            setFalse,
            toggle,
            set,
        }
    }, [isTrue, setFalse, setTrue, toggle, set])
}

export type UseBooleanType = ReturnType<typeof useBoolean>
