import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { generatePath, useHistory, useLocation } from 'react-router-dom'

import {
    ThemeProvider,
    VideoPlayer as VideoPlayerComponent,
    slicingSuggestionsToChaptersTransformer,
    slicingSuggestionsToThumbnailsTransformer,
    stepsToCTAsTransformer,
} from '@guidde/design-system'
import { type MediaPlayerInstance } from '@vidstack/react'

import {
    AnyPlaybookType,
    videoPlayerDefaultState,
    VideoOverlayDataType,
    PlaylistType,
    TranslationType,
} from 'app/types'

import { CollectVideoPlayAnalytics, useBoolean, useCtaReport, useQuery, useVideoLoadAnalytics } from 'hooks'
import { host, isQG } from 'modules'

import { Feedback } from 'components/Feedback'
import { checkToOpenPlaybookPauseScreen, PauseScreen } from 'components/PauseScreen'

import { logToAnalytics, playbookToAnalyticsProps, generatePlaybookURL } from 'modules'
import { paths } from 'app/paths'

type Props = {
    overlayData: VideoOverlayDataType
    videos: Array<AnyPlaybookType>
    playlist?: PlaylistType
}

export const sortTranslations = (translations: Array<TranslationType>) => {
    return translations.sort((a, b) => {
        if (a.isPrimary && !b.isPrimary) return -1
        if (!a.isPrimary && b.isPrimary) return 1

        return a.language.langName > b.language.langName ? 1 : -1
    })
}

export const VideoPlayer = ({ videos, overlayData, playlist }: Props) => {
    const history = useHistory()

    const [currentVideoIndex, setCurrentVideoIndex] = useState<number>(0)

    const playbook = videos?.[currentVideoIndex] ?? null

    const isPlayerMounted = useBoolean()
    const setPlayerMounted = isPlayerMounted.setTrue
    const playerRef = useRef(videoPlayerDefaultState)
    const setPlayerRef = useCallback(
        (element: MediaPlayerInstance) => {
            setPlayerMounted()

            playerRef.current = {
                getPlayingState: () => !element.paused,
                getDuration: () => element.duration,
                // Important to call element functions in such way, as function reference might change
                pause: () => {
                    element.pause()
                },
                addEventListener: (...args) => {
                    element.addEventListener(...args)
                },
                removeEventListener: (...args) => {
                    element.removeEventListener(...args)
                },
                eventNames: {
                    play: 'play',
                    pause: 'pause',
                    timeUpdate: 'time-update',
                },
                getCurrentTime: () => element.currentTime,
            }
        },
        [setPlayerMounted],
    )

    const query = new URLSearchParams(useLocation()?.search)
    const autoPlayFromQuery = query.get('autoPlay')
    const parsedAutoPlayFromQuery = query.get('autoPlay') === 'true'

    const autoPlay = autoPlayFromQuery ? parsedAutoPlayFromQuery : false

    const $publicFF = useQuery(`/c/v1/feature-flags-public?orgId=${playbook.uploadedByOrgId}`, {
        method: 'GET',
    })

    const showPauseScreen = useBoolean()
    const hasShowedPauseScreen = useBoolean()
    const resetHasShowedPauseScreen = hasShowedPauseScreen.setFalse
    useEffect(() => resetHasShowedPauseScreen(), [playbook.id, resetHasShowedPauseScreen])

    const isPlaylist = Boolean(playlist)
    const formattedPlaylist =
        (isPlaylist &&
            videos?.map((video: any) => ({
                source: video?.url,
                poster: video?.screenshotUrl,
                subtitles: video?.subtitles?.length
                    ? video.subtitles
                    : video?.subtitlesUrl
                      ? [
                            {
                                src: video?.subtitlesUrl,
                                label: 'English',
                                language: 'en-US',
                            },
                        ]
                      : [],
                chapters: slicingSuggestionsToChaptersTransformer(video?.slicingSuggestion ?? []),
                thumbnails: slicingSuggestionsToThumbnailsTransformer(video?.slicingSuggestion ?? []),
                ctas: stepsToCTAsTransformer((video?.steps as any) ?? [], host).map(cta => {
                    if (cta.type === 'multiple') {
                        return {
                            ...cta,
                            actions: cta.actions.map(action => {
                                if (action.goToType === 'guiddeLink') {
                                    return {
                                        ...action,
                                        goToHandler: () => {
                                            const playbookPath = generatePath(paths.playbookDetails, {
                                                playbookId: action.goToId,
                                            })

                                            const playlistPath = generatePath(paths.playlistDetails, {
                                                playlistId: action.goToId,
                                            })

                                            const path = action.goToMode === 'playlist' ? playlistPath : playbookPath
                                            history.push(path)
                                        },
                                    }
                                }
                                return action
                            }),
                        }
                    }
                    return {
                        ...cta,
                        action: {
                            ...cta.action,
                            ...(cta.action.goToType === 'guiddeLink' && {
                                goToHandler: () => {
                                    const playbookPath = generatePath(paths.playbookDetails, {
                                        playbookId: cta.action.goToId,
                                    })

                                    const playlistPath = generatePath(paths.playlistDetails, {
                                        playlistId: cta.action.goToId,
                                    })

                                    const path = cta.action.goToMode === 'playlist' ? playlistPath : playbookPath
                                    history.push(path)
                                },
                            }),
                        },
                    }
                }),
                title: video?.title,
                shareLink: generatePlaybookURL(video),
            }))) ||
        []

    const isQuickGuidde = isQG(playbook)

    const translations = useMemo(() => {
        if (!isQuickGuidde) return { playbookIdsSchema: {}, playerMapping: [] }

        const translations = playbook.primaryPlaybook?.translations || playbook.translations || []

        return sortTranslations(translations).reduce<{
            playbookIdsSchema: { [key: string]: string }
            playerMapping: { langCode: string; langName: string }[]
        }>(
            (acc, { language, id }) => ({
                playbookIdsSchema: {
                    ...acc.playbookIdsSchema,
                    [language.langCode]: id,
                },
                playerMapping: [...acc.playerMapping, { langCode: language.langCode, langName: language.langName }],
            }),
            { playbookIdsSchema: {}, playerMapping: [] },
        )
    }, [playbook, isQuickGuidde])

    const onTranslationChange = useCallback(
        (langCode: string) => {
            const playbookId = translations.playbookIdsSchema[langCode]

            if (!playbookId) {
                console.error('[onTranslationChange]: playbookId is not defined')
                return
            }

            history.push(
                generatePath(paths.playbookDetails, {
                    playbookId: playbookId,
                }),
            )
        },
        [history, translations.playbookIdsSchema],
    )

    const video = {
        source: playbook?.url,
        ...(isQuickGuidde && {
            translations: translations.playerMapping,
            primaryPlaybook: playbook?.primaryPlaybook,
        }),
        poster: playbook?.screenshotUrl,
        subtitles: playbook?.subtitles?.length
            ? playbook.subtitles
            : playbook?.subtitlesUrl
              ? [
                    {
                        src: playbook?.subtitlesUrl,
                        label: 'English',
                        language: 'en-US',
                    },
                ]
              : [],
        chapters: playbook?.chapters?.length
            ? playbook?.chapters
            : slicingSuggestionsToChaptersTransformer(playbook?.slicingSuggestion ?? []),
        thumbnails: slicingSuggestionsToThumbnailsTransformer(playbook?.slicingSuggestion ?? []),
        ctas: stepsToCTAsTransformer((playbook?.steps as any) ?? [], host).map(cta => {
            if (cta.type === 'multiple') {
                return {
                    ...cta,
                    actions: cta.actions.map(action => {
                        if (action.goToType === 'guiddeLink') {
                            return {
                                ...action,
                                goToHandler: () => {
                                    const playbookPath = generatePath(paths.playbookDetails, {
                                        playbookId: action.goToId,
                                    })

                                    const playlistPath = generatePath(paths.playlistDetails, {
                                        playlistId: action.goToId,
                                    })

                                    const path = action.goToMode === 'playlist' ? playlistPath : playbookPath
                                    history.push(path)
                                },
                            }
                        }
                        return action
                    }),
                }
            }
            return {
                ...cta,
                action: {
                    ...cta.action,
                    ...(cta.action.goToType === 'guiddeLink' && {
                        goToHandler: () => {
                            const playbookPath = generatePath(paths.playbookDetails, {
                                playbookId: cta.action.goToId,
                            })

                            const playlistPath = generatePath(paths.playlistDetails, {
                                playlistId: cta.action.goToId,
                            })

                            const path = cta.action.goToMode === 'playlist' ? playlistPath : playbookPath
                            history.push(path)
                        },
                    }),
                },
            }
        }),
        title: playbook?.title,
        shareLink: generatePlaybookURL(playbook),
    }

    const analyticsProps = useMemo(() => playbookToAnalyticsProps(playbook, playlist), [playbook, playlist])

    const { reportEvent: reportCtaClick } = useCtaReport({
        playbook,
        videoPlayer: playerRef.current,
        playlist,
    })

    useVideoLoadAnalytics({ playbook })
    useEffect(() => {
        if (playlist) {
            logToAnalytics('open_playlist', {
                'playlist-app': playlist.applications?.[0]?.applicationName || null,
                'playlist-appId': playlist.applications?.[0]?.applicationId || null,
                'playlist-tags': playlist.tags,
                'playlist-id': playlist.id,
                'playlist-title': playlist.title,
            })
        }
    }, [playlist])

    if ($publicFF.isValidating) {
        return null
    }

    return (
        <>
            {isPlayerMounted.isTrue && (
                <CollectVideoPlayAnalytics
                    playbook={playbook}
                    videoPlayer={playerRef.current}
                />
            )}

            <ThemeProvider>
                <VideoPlayerComponent
                    {...(isPlaylist ? { playlist: formattedPlaylist } : { video })}
                    playerProps={{
                        playerRef: setPlayerRef,

                        canRenderWithoutVideo: true,

                        isAutoplay: autoPlay,
                        isMuted: autoPlay,

                        hasTranslations: Number(translations.playerMapping.length) > 0,
                        activeTranslation: isQuickGuidde
                            ? translations.playerMapping.find(
                                  translation => translation.langCode === playbook.language?.langCode,
                              )
                            : undefined,
                        onTranslationChange,

                        onPause: () => {
                            checkToOpenPlaybookPauseScreen({
                                videoPlayer: playerRef.current,
                                overlayData,
                                openPauseScreen: () => {
                                    hasShowedPauseScreen.setTrue()
                                    showPauseScreen.setTrue()
                                },
                                isPauseScreenShowed: hasShowedPauseScreen.isTrue,
                            })

                            logToAnalytics('videoStopClicked', analyticsProps)
                        },
                        onPlay: () => {
                            logToAnalytics('videoPlayClicked', analyticsProps)
                        },

                        onMute: () => {
                            logToAnalytics('videoMuted', analyticsProps)
                        },
                        onUnmute: () => {
                            logToAnalytics('videoUnmuted', analyticsProps)
                        },

                        // onSpeedChange: speed => {},

                        // onCaptionsChange: language => {},

                        hasChapterNav: true,

                        hasTopTitle: true,
                        topTitleType: 'videoOnly',
                        hasBottomTitle: true,
                        bottomTitleType: 'chapterOnly',

                        // @ts-ignore
                        hasShareButton: !$publicFF.data?.hideButtonShareInEmbed,
                        shareButtonPosition: 'right',
                        onShare: () => {
                            const target = isPlaylist ? 'playlist' : 'pb'
                            logToAnalytics(`share_${target}_btn_clicked`, analyticsProps)
                        },
                        onShareSuccess: () => {
                            logToAnalytics('copyLink', analyticsProps)
                        },

                        hasFullscreenMode: true,
                        onFullscreenModeToggle: () => {
                            logToAnalytics('videoFullscreenClicked', analyticsProps)
                        },
                        hasPIPMode: true,
                        onPIPModeToggle: () => {
                            logToAnalytics('videoPipClicked', analyticsProps)
                        },

                        hasCTAs: true,
                        onCtaClick: reportCtaClick,

                        // @ts-ignore
                        hasWatermark: !$publicFF.data?.canHideWatermarkEmbed,

                        hasPauseScreen: true,
                        pauseScreenSlot: showPauseScreen.isTrue && (
                            <PauseScreen
                                playbook={playbook}
                                onClose={showPauseScreen.setFalse}
                            />
                        ),

                        hasBranding: true,
                        brandLogoSrc: overlayData?.brandKit.logo,

                        hasEndScreen: true,
                        endScreenSlot: (
                            <Feedback
                                playbook={playbook}
                                overlayData={overlayData}
                            />
                        ),

                        onPlaylistNav: (direction, videoIndex) => {
                            if (typeof videoIndex !== 'undefined') {
                                setCurrentVideoIndex(videoIndex)
                            }
                            // if (direction === 'prev') {
                            // } else {
                            // }
                        },
                    }}
                />
            </ThemeProvider>
        </>
    )
}
