import { useCallback, useMemo } from 'react'

import { useTheme, useMediaQuery } from '@mui/material'

export const useWindowView = () => {
    const theme = useTheme()
    const mdDown = useMediaQuery(theme.breakpoints.down('sm'))
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'))

    const isWindowWidthSmaller = useCallback((value: number) => useMediaQuery(theme.breakpoints.down(value)), [theme])

    return useMemo(
        () => ({
            mdDown,
            xsDown,
            isWindowWidthSmaller,
        }),
        [mdDown, xsDown, isWindowWidthSmaller],
    )
}
