import { Suspense, memo } from 'react'
import { Switch, Route } from 'react-router-dom'

import { VideoLoadingSkeleton } from './VideoLoadingSkeleton'

import PageNotFound from 'pages/PageNotFound'
import { LoginPage } from 'pages/LoginPage'

import { routes } from 'app/routes'

import { useAuth } from 'hooks'

export const AppRouter = memo(() => {
    const { isLoading, showLoginPage } = useAuth()

    if (isLoading) return null

    if (showLoginPage) return <LoginPage />

    return (
        <Suspense fallback={<VideoLoadingSkeleton />}>
            <Switch>
                {routes.map(route => (
                    <Route
                        key={route.path}
                        exact={true}
                        path={route.path}
                    >
                        {route.component}
                    </Route>
                ))}
                <Route component={PageNotFound} />
            </Switch>
        </Suspense>
    )
})
