export const videoPlayerDefaultState = {
    getPlayingState: () => false,
    getDuration: () => 0,
    pause: () => {},
    addEventListener: (_type: string, _callback: (_event: any) => void) => {},
    removeEventListener: (_type: string, _callback: (_event: any) => void) => {},
    eventNames: {
        play: '',
        pause: '',
        timeUpdate: '',
    },
    getCurrentTime: () => 0,
}
export type VideoPlayer = typeof videoPlayerDefaultState
