import { useCallback, useMemo } from 'react'

import { AnyPlaybookType, PlaylistType, VideoPlayer } from 'app/types'
import { isQG, logToAnalytics, playbookToAnalyticsProps } from 'modules'
import { useQueryParams } from 'hooks'

type Props = {
    playbook: AnyPlaybookType
    videoPlayer: VideoPlayer
    playlist?: PlaylistType
}

export const useCtaReport = ({ playbook, videoPlayer, playlist }: Props) => {
    const query = useQueryParams('track_link_name')

    const getActiveData = useCallback(() => {
        const activeTime = Math.floor(videoPlayer.getCurrentTime())

        const activeChapterIdx = playbook.slicingSuggestion?.findIndex(
            chapter => chapter.start <= activeTime && chapter.end >= activeTime,
        )

        if (activeChapterIdx === undefined) return {}

        if (isQG(playbook)) {
            return {
                index: playbook.steps[activeChapterIdx]?.id || '',
                type: 'step',
                activeChapterIdx,
            }
        }

        return {
            type: 'chapter',
            index: activeChapterIdx,
        }
    }, [playbook, videoPlayer])

    const reportEvent = useCallback(
        (cta: unknown) => {
            logToAnalytics('openCta', {
                ...playbookToAnalyticsProps(playbook, playlist),
                ...{
                    cta,
                    ...getActiveData(),
                    orgName: query || '',
                },
            })
        },
        [getActiveData, playbook, playlist, query],
    )

    return useMemo(
        () => ({
            reportEvent,
        }),
        [reportEvent],
    )
}
