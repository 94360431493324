import { Skeleton } from '@mui/material'

export const VideoLoadingSkeleton = () => {
    const iframeWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

    const iframeHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

    return (
        <Skeleton
            animation="wave"
            variant="rectangular"
            width={iframeWidth}
            height={iframeHeight}
            style={{
                background: '#D9D9D9',
                borderRadius: 4,
                overflow: 'hidden',
            }}
        />
    )
}
